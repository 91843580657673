<template>
    <div>
        <div class="account-overview">
            <!-- 没有订单-->
            <!--          <div class="account-orders">-->
            <!--             <h1>My Order<span>(0)</span></h1>-->
            <!--            <div class="OrderCountZero">You have not previously ordered as a registered customer.</div>-->
            <!--            <div><a href="#">Continue shopping</a></div>-->
            <!--          </div>-->
            <!--  有订单-->
            <div class="showBags">
                <div class="shopping-bag-content ">
                    <div class="customScrollbar shopping-bag-list ready ps ps--active-y">
                        <el-tabs v-model="status" @tab-click="handleClickTag">
                            <el-tab-pane
                                :label="item.label"
                                :name="item.name"
                                v-for="(item, index) in tabsItems"
                                :key="index"
                            >
                <span slot="label">
                    {{ item.label }}
                    <el-badge
                        v-if="orderSummary[item.name] > 0"
                        :value="orderSummary[item.name]"
                        :max="99"
                        style="margin-bottom: 10px; margin-left: -5px;"
                    />
                </span>
                                <br/>
                                <div class="shopping-bag-list-product">
                                    <div
                                        v-for="(order, index) in orderList"
                                        :key="index"
                                        style="margin-bottom: 30px; border-bottom: 1px solid #d8d7d5"
                                    >
                                        <div
                                            class="item-message"
                                            style="margin-bottom: 15px; display: flex; justify-content: space-between; align-content: center;"
                                        >
                                            <span class="items">NO: {{ order.orderNo }}</span>
                                            <span class="items">Status: {{ order.orderStatus }}</span>
                                            <span >Order time: {{ order.createdAt }}</span>
                                            <span v-if="order.paidAt"
                                            >Payment time: {{ order.paidAt }}</span
                                            >
                                        </div>
                                        <div
                                            class="shopping-bag-element"
                                            v-for="(product, productIndex) in order.products"
                                            :key="productIndex"
                                        >
                                            <div class="shopping-bag-element-image">
                                                <img :src="product.productThumbnail"/>
                                            </div>
                                            <div class="shopping-bag-element-info">
                                                <div class="element-info element-title">
                                                    <span>{{ product.productName }}</span>
                                                </div>
                                                <div class="element-info element-price">
                                                    <div class="itemPrice">
                            <span
                            >HK$
                              {{
                                    fenToYuan(product.price * product.quantity)
                                }}</span
                            >
                                                    </div>
                                                </div>
                                                <div v-if="product.pack">
                                                    <span>Pack: {{ product.pack.name }}</span>
                                                </div>
                                                <div class="element-info element-size-color">
                                                    <div
                                                        :key="k"
                                                        v-for="(sku, k) in product.productSku"
                                                        class="element-size"
                                                    >
                                                        {{ k }}: {{ sku }}
                                                    </div>
                                                    <div>Quantity: {{ product.quantity }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="shopping-bag-footer">
                                            <div class="status-button">
                                                <div
                                                    v-if="order.orderStatus === 'unpaid'"
                                                >
                                                    <el-button
                                                        class="status-button-style cancel"
                                                        @click="orderCancel(order.id)"
                                                    >
                                                        Cancel
                                                    </el-button>
                                                </div>
                                                <div
                                                    v-if="order.orderStatus === 'unpaid'"
                                                >
                                                    <form :action="paymentApiUrl" method="POST">
                                                        <input type="hidden" name="orderId" v-model="order.id"/>
                                                        <input
                                                            type="hidden"
                                                            name="userId"
                                                            :value="order.userId"
                                                        />
                                                        <input
                                                            id="paymentData"
                                                            type="hidden"
                                                            name="stripeToken"
                                                            value=""
                                                        />
                                                        <noscript
                                                        >You must
                                                            <a href="http://www.enable-javascript.com" target="_blank"
                                                            >enable JavaScript</a
                                                            >
                                                            in your web browser in order to pay via Stripe.
                                                        </noscript>
                                                        <el-button
                                                            :loading="paymentBtnLoading"
                                                            :disabled="paymentBtnDisable"
                                                            @click="checkout"
                                                            id="paymentBtn"
                                                            class="status-button-style"
                                                            value="Proceed to checkout"
                                                            :data-key="publishableKey"
                                                            :data-amount="order.totalFee"
                                                            data-name="BING XU"
                                                            data-description="Bingxu Collection"
                                                            data-image=""
                                                            data-locale="auto"
                                                            data-billing-address="false"
                                                            data-zip-code="false"
                                                        >
                                                            <span class="icon"></span>
                                                            <span class="text">Proceed to checkout</span>
                                                        </el-button>
                                                    </form>
                                                </div>
                                                <div
                                                    v-if="order.orderStatus === 'waiting_for_take_delivery'"
                                                >
                                                    <el-button
                                                        class="status-button-style"
                                                        @click="orderDelivery(order.id)"
                                                    >
                                                        Take delivery
                                                    </el-button>
                                                </div>
                                            </div>
                                            <div class="prices products-price">
                                                <span class="label">Subtotal:</span>
                                                <span class="valueToPay"
                                                >HK$ {{ fenToYuan(order.totalFee) }}</span
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </el-tab-pane>
                        </el-tabs>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// import myAccount from "@/views/myAccount";
import {cancelOrder, deliveryOrder, getOrders} from "../api/api";
import {priceTransform} from "@/utils/util";
import $ from "jquery";

export default {
    name: "orders",
    components: {
        // myAccount
    },
    data() {
        return {
            paymentApiUrl: process.env.VUE_APP_BASE_API + "/web-site/payment",
            publishableKey:
                "pk_live_51IiFWrKrFCJwUtPru600kTq7kpifA02my6Od3scmL7FKNyxnoahfwTjw1ye99ejYZTa7B7eH9eJOyvUq62RuNXDW00Kz5JSzLu",
            paymentBtnLoading: false,
            paymentBtnDisable: false,
            status: "all",
            orderNum: null,
            orderList: [],
            orderSummary: {
                all: 0,
                unpaid: 0,
                waiting_for_take_delivery: 0,
                cancelled: 0
            },
            tabsItems: [
                {label: "All", name: "all"},
                {label: "Waiting for payment", name: "unpaid"},
                {label: "Waiting for take delivery", name: "waiting_for_take_delivery"},
                {label: "Cancelled", name: "cancelled"}
            ]
        };
    },
    mounted() {
        this.myOrders();
    },
    methods: {
        async orderCancel(item) {
            const res = await cancelOrder({item});
            if (res.code === 0) {
                this.myOrders();
            }
            console.log(res);
        },
        async orderDelivery(item) {
            const res = await deliveryOrder({item});
            if (res.code === 0) {
                this.myOrders();
            }
            console.log(res);
        },
        handleClickTag(tab, event) {
            this.status = tab.name;
            this.myOrders();
        },
        fenToYuan(fen) {
            return priceTransform(fen);
        },
        async myOrders() {
            const response = await getOrders(this.status);
            if (response.code === 0) {
                this.orderNum = response.data;
                this.orderList = response.data.list;
                this.orderSummary.unpaid = response.data.waitingPaid;
                this.orderSummary.waiting_for_take_delivery = response.data.waitingDelivery;
            }

            console.log(this.orderList);
        },
        async checkout(event) {
            this.paymentBtnDisable = true;
            this.paymentBtnLoading = true;

            event.preventDefault();
            let $button = $("#paymentBtn"),
                $form = $button.parents("form");

            let opts = $.extend({}, $button.data(), {
                token: function (result) {
                    $form
                        .append(
                            $("#paymentData").attr({
                                type: "hidden",
                                name: "stripeToken",
                                value: result.id
                            })
                        )
                        .submit();
                }
            });

            StripeCheckout.open(opts);

            setTimeout(() => {
                this.paymentBtnDisable = false;
                this.paymentBtnLoading = false;
            }, 3000);
        }
    }
};
</script>
<style scoped>
/deep/ .el-button:hover {
    background-color: #fff;
    color: #606266;
}

/deep/ .el-tabs__item.is-active {
    color: black;
    font-weight: 500;
}

/deep/ .el-tabs__item:hover {
    color: black;
    cursor: pointer;
}

/deep/ .el-tabs__item {
    font-weight: 400;
}

/deep/ .el-tabs__active-bar {
    background-color: black;
}

/deep/ .el-tabs__nav-wrap::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: rgb(216, 215, 213);
    z-index: 1;
}

@media (max-width: 1280px) {
    .status-button {
        justify-content: flex-end;
        margin-bottom: 1.5rem;
    }

    /deep/ .el-tabs__header {
        position: relative;
        margin: 60px 0 15px;
        padding: 0 0.5rem;
    }
}
</style>
<style lang="scss" scoped>
a {
    text-decoration: none;
    color: inherit;
}

.status-button {
    display: flex;

    .status-button-style {
        border: 1px solid #212121;
        padding: 0.5rem 0.7rem;
        margin-left: 1rem;
        cursor: pointer;
        border-radius: 0;
    }

    .status-button-style.cancel {
        background-color: #212121;
        color: #fff;
    }
}

@media (min-width: 1280px) {
    .account-orders {
        width: 51.875rem;

        h1 {
            margin-bottom: 1rem;
        }

        .OrderCountZero {
            margin-bottom: 1rem;
        }
    }
    .account-overview {
        width: 51.875rem;
        margin: 0 auto;
    }
    .showBags {
        .shopping-bag-content {
            display: flex;
            flex-direction: column;
            padding: 0;
            overflow: hidden;
            height: calc(100% - 3.125rem - 1.75rem - 7.5625rem);

            .shopping-bag-list {
                display: flex;
                flex-direction: column;
                height: 100%;
                overflow: hidden;
                position: relative;

                .shopping-bag-element {
                    align-items: flex-start;
                    display: flex;
                    position: relative;
                    margin-bottom: 5px;

                    .shopping-bag-element-image {
                        position: relative;
                        margin-right: 2%;
                        min-width: 10%;
                        width: 10%;
                        z-index: 2;

                        img {
                            //top: 0;
                            //left: 0;
                            //position: absolute;
                            width: 100%;
                        }
                    }

                    .shopping-bag-element-info {
                        align-self: flex-end;
                        flex-grow: 2;
                        line-height: 1.6rem;
                        //padding: 1.5rem 5% 1px 0;
                        .element-price {
                            //margin-bottom: 1.25rem;
                            .itemPrice {
                                display: inline-flex;
                            }
                        }

                        .removeBtn {
                            margin-top: 1rem;

                            button {
                                cursor: pointer;
                                text-decoration: none;
                                border: none;
                                line-height: 1;
                            }
                        }

                        .removeBtn:hover button {
                            border-bottom: 1px solid;
                        }
                    }
                }
            }
        }

        .shopping-bag-footer {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            height: 7.5625rem;
            justify-content: flex-end;

            .prices {
                align-items: center;
                display: flex;
                width: 100%;
                justify-content: space-between;
                height: 3.375rem;
            }

            .goToCheckoutLink {
                background-color: #212121;
                border-width: 0;
                color: #fff;
                display: block;
                padding: 0.3125rem 0.3125rem 0.375rem;
                text-align: center;
                width: 100%;
            }
        }
    }
}

@media (max-width: 1280px) {
    .item-message {
        display: flex;
        flex-direction: column;
        .items {
            margin-bottom: .3rem;
        }
    }
    .shopping-bag-list-product {
        //margin-top: 3.1rem;
        //border-top: 1px solid #d8d7d5;
        padding: 0 1.875rem 1.875rem 1.875rem;

        .shopping-bag-element {
            display: flex;
            padding: 1rem 0;

            .shopping-bag-element-info {
                width: 50%;
            }

            .shopping-bag-element-image {
                width: 50%;
                height: 200px;
                background-color: #f8f7f5;

                img {
                    width: 100%;
                    max-height: 100%;
                }
            }

            .shopping-bag-element-info {
                margin-left: 0.5rem;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
            }
        }

        .shopping-bag-footer {
            margin: 1.5rem 0;

            .prices.products-price {
                display: flex;
                justify-content: space-between;
            }
        }
    }
}
</style>
